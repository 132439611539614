import { twicePI } from './utility';
import { Vec2, dot, subtract } from './vector';

export function pointIntersectsLine(p: Vec2, origin: Vec2, direction: Vec2): boolean {
  const dxc = p.x - origin.x;
  const dyc = p.y - origin.y;
  const cross = dxc * direction.y - dyc * direction.x;
  return cross === 0;
}

export function pointIntersectsSegment(p: Vec2, s1: Vec2, s2: Vec2): boolean {
  const dxc = p.x - s1.x;
  const dyc = p.y - s1.y;
  const dxl = s2.x - s1.x;
  const dyl = s2.y - s1.y;
  const cross = dxc * dyl - dyc * dxl;
  if (cross !== 0) return false;
  const dot = dxc * dxl + dyc * dyl;
  if (dot < 0) return false;
  const sqLength = dxl * dxl + dyl * dyl;
  return dot <= sqLength || (p.x === s1.x && p.y === s1.y) || (p.x === s2.x && p.y === s2.y);
}

export function pointInsideSegmentSector(p: Vec2, s1: Vec2, s2: Vec2): boolean {
  const dot1 = dot(subtract(p, s1), subtract(s2, s1));
  const dot2 = dot(subtract(p, s2), subtract(s1, s2));
  return dot1 >= 0 && dot2 >= 0;
}

export function linesIntersection(a1: Vec2, a2: Vec2, b1: Vec2, b2: Vec2, out?: Vec2): Vec2 | undefined {
  const aDirection = subtract(a2, a1);
  const bDirection = subtract(b2, b1);
  const determinant = bDirection.x * aDirection.y - bDirection.y * aDirection.x;
  if (Math.abs(determinant) < Number.EPSILON) return undefined;

  const beta = ((a1.x - b1.x) * aDirection.y - (a1.y - b1.y) * aDirection.x) / determinant;
  out = out ?? { x: 0, y: 0 };
  out.x = b1.x + beta * bDirection.x;
  out.y = b1.y + beta * bDirection.y;
  return out;
}

export function lineIntersectsLine(a1: Vec2, a2: Vec2, b1: Vec2, b2: Vec2, out?: Vec2): boolean {
  return linesIntersection(a1, a2, b1, b2, out) !== undefined;
}

export function segmentIntersectsLine(s1: Vec2, s2: Vec2, l1: Vec2, l2: Vec2, out?: Vec2): boolean {
  const denom = (l2.y - l1.y) * (s2.x - s1.x) - (l2.x - l1.x) * (s2.y - s1.y);
  if (denom === 0) {
    return false;
  }
  const ua = ((l2.x - l1.x) * (s1.y - l1.y) - (l2.y - l1.y) * (s1.x - l1.x)) / denom;
  const ub = ((s2.x - s1.x) * (s1.y - l1.y) - (s2.y - s1.y) * (s1.x - l1.x)) / denom;
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false;
  }
  if (out !== undefined) {
    out.x = s1.x + ua * (s2.x - s1.x);
    out.y = s1.y + ua * (s2.y - s1.y);
  }
  return true;
}

export function segmentIntersectsSegment(a1: Vec2, a2: Vec2, b1: Vec2, b2: Vec2, out?: Vec2): boolean {
  out = out ?? { x: 0, y: 0 };
  return (
    lineIntersectsLine(a1, a2, b1, b2, out) &&
    pointInsideSegmentSector(out, a1, a2) &&
    pointInsideSegmentSector(out, b1, b2)
  );
}

export function pointOnCircle(radius: number, angle: number): Vec2 {
  const x = radius * Math.cos(angle);
  const y = radius * Math.sin(angle);
  return { x, y };
}

export function pointOnCircleClockwise(radius: number, angle: number): Vec2 {
  const x = radius * Math.cos(angle - Math.PI / 2);
  const y = -radius * Math.sin(angle - Math.PI / 2);
  return { x, y };
}

export function directionFromAngle(radians: number): Vec2 {
  return {
    x: Math.cos(radians),
    y: Math.sin(radians),
  };
}

export function directionFromAngleClockwise(radians: number): Vec2 {
  return {
    x: Math.sin(radians),
    y: Math.cos(radians),
  };
}

export function angleFromVector(vec: Vec2): number {
  return Math.atan2(vec.y, vec.x);
}

export function angleFromVectorClockwise(vec: Vec2): number {
  const angle = Math.atan2(vec.x, vec.y);
  return angle < 0 ? angle + 2 * Math.PI : angle;
}

export function circlePerimeter(radius: number): number {
  return twicePI * radius;
}

export function arcLength(radius: number, angle: number): number {
  return radius * angle;
}

export function arcLenghtAngle(radius: number, arcLength: number): number {
  return arcLength / radius;
}
